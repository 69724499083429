.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-inline: 2rem;
  align-self: stretch;
}

.loginButtonRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.loginButton {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  height: 44px;
  background: var(--primary-color);
  border-radius: 5px;
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--blue);
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 40px;
  @media (max-width: 600px) {
    margin-top: 40px;
  }
}

.loginButton:disabled {
  background-color: var(--black-light);
  cursor: not-allowed;
}

.loginButton2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  height: 44px;
  background: var(--primary-color);
  border-radius: 5px;
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--blue);
  cursor: pointer;
  margin: auto;
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  .loginButton2 {
    margin-top: 40px;
  }
  .loginButton {
    margin-top: 40px;
  }
}

.formFooter {
  display: flex;
  column-gap: 1rem;
  cursor: pointer;
  margin-top: 40px;
}

.blueLinkText,
.yellowLinkText {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--blue);
  cursor: pointer;
}

.yellowLinkText {
  font-weight: 500;
  color: var(--primary-color);
}
.yellowLinkText2 {
  font-weight: 500;
  color: var(--primary-color);
}
.yellowLinkText2:hover {
  font-weight: 500;
  color: var(--primary-color) !important;
}
a:hover {
  color: var(--primary-color) !important;
}
