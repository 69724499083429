.closeButton {
  position: relative;
}

.closeButton img {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
}

.closeButton:hover {
  cursor: pointer;
}

.closeButton:hover img {
  filter: contrast(200%);
}

.dialogContent {
  width: 100%;
  max-width: 1051px;
  margin: 0;
  padding: 0 120px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .dialogContent {
    padding: 0 50px 0px;
  }
}

@media screen and (max-width: 767px) {
  .dialogContent {
    padding: 0 30px 0px;
  }
}
